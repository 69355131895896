import axios from 'axios';

export const generateAxiosInstance = () => {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)mints_contact_session_token\s*\=\s*([^;]*).*$)|^.*$/, "$1");

    axios.defaults.headers.common['x-api-key'] = '9c6334a7-8215-46ff-8ec9-f442cdc43cac';
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['ApiKey'] = '99296b00807e5fe3931bdb0b7b48b32f';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return axios;
};